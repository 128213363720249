import axios from 'axios';
import {SearchResult} from "@/models/SearchResult";
import {Truck} from "@/models/category/Truck";

export class TruckService {
    url = `${process.env.VUE_APP_CORE_URL}api/v1/truck`;

    search(keyword: string, pageIndex: number, pageSize: number):
        Promise<SearchResult<Truck>> {
        return axios.get(this.url, {
            params: {
                pageIndex: pageIndex,
                keyword: keyword,
                pageSize: pageSize
            }
        });
    }

    update(item: Truck):
        Promise<any> {
        return axios.post(`${this.url}`, item);
    }
}
