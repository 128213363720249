



















































































import {Component, Vue, Watch} from 'vue-property-decorator';
import RouteSelect from "@/components/category/RouteSelect.vue";
import {TruckService} from '@/services/config/TruckService';
import vSelect from 'vue-select'
import filter from 'lodash/filter';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import UserSuggestion from "@/components/suggestion/user-suggestion/UserSuggestion.vue";

@Component({
    components: {
        UserSuggestion
    }
})

export default class UserConfig extends Vue {
    //@Ref('userEditTabAccount') userEditTabAccount!: any;
    currentPage: number = 1;
    // value: string = "123";
    // text: string = "Hello tuyến đường";
    trucks: any = [];
    totalPages = 0;
    pageSize = 10;
    popupActive: Boolean = false;
    // moreSettingPopupActive: Boolean = false;
    popupInventoryActive: boolean = false;
    keyword: string = '';
    selected: any = [];
    private TruckService: TruckService;
    currentTruck: any = {};
    backgroundLoading: string = 'primary';
    colorLoading: string = '#fff';
    totalRows: number = 0;
    branches: any = [];
    inventories: any = [];
    saleChannels: any = [];
    debounce: any = null;

    constructor() {
        super();
        this.TruckService = new TruckService();
    }

    beforeMount() {
    }

    mounted() {
        this.search();
    }
    async setPageSize(size: number) {
        this.pageSize = size;
        this.currentPage = 1;
        this.search();
    }

    openPopup(item: any) {

        this.popupInventoryActive = true;

        this.currentTruck = item;
    }

    async saveMore(btnId: string) {
        this.$vs.loading({
            background: this.backgroundLoading,
            color: this.colorLoading,
            container: "#" + btnId,
            scale: 0.45
        })
    }

    @Watch('keyword')
    onKeyWordInputChange(value: string, oldValue: string) {
        if (this.debounce) {
            this.debounce.cancel();
        }

        this.currentPage = 1;
        this.debounce = debounce(() => this.search(), 500);
        this.debounce();
    }

    @Watch('currentPage')
    onCurrentPageChange(value: string, oldValue: string) {
        this.search();
    }

    openLoading() {
        this.$vs.loading()
    }

    beginSearch() {
        if (this.keyword == undefined || this.keyword.length < 2) {
            return;
        }

        this.search();
    }

    onSelect(value: any) {
        this.currentTruck.driverId = value[0].id;
        this.currentTruck.driver = value[0].text;
        this.currentTruck.driverAvatar = value[0].avatar;

        this.TruckService.update(this.currentTruck);
        this.popupInventoryActive = false;
    }

    private async search() {
        this.openLoading();
        const result = await this.TruckService.search(this.keyword, this.currentPage, this.pageSize);

        if (result.code > 0) {
            this.trucks = result.items;
            this.setTotalPage(result.totalRows);
        }

        setTimeout(() => {
            this.$vs.loading.close()
        }, 500);
    }

    private setTotalPage(totalRows: number) {
        this.totalRows = totalRows;

        if (totalRows == 0) {
            this.totalPages = 0;
        }
        if (totalRows <= this.pageSize) {
            this.totalPages = 1;
        }
        this.totalPages = Math.ceil((totalRows / this.pageSize));
    }
}
